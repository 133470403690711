<template>

    <section class="navbar-wrapper">

        <a href="../#/" @click="this.bold = 'main'">
            <img src="@/assets/logo-s1.png" alt="logo">
        </a>

        <header>
            <span>architekt Marek Stojanowski</span>

            <nav>
                <a href="../#/" :class="{ decoration: bold === 'main' }" @click="this.bold = 'main'">wybrane projekty / realizacje</a>
                <a href="../#/o-pracowni" :class="{ decoration: bold === 'about' }" @click="this.bold = 'about'">o pracowni</a>
                <a href="../#/kontakt" :class="{ decoration: bold === 'contact' }" @click="this.bold = 'contact'">kontakt</a>
            </nav>
        </header>

    </section>

</template>

<script>
    export default {
        name: 'Navbar',

        data() {
            return {
                bold: ''
            }
        },

        methods: {
            menuSectionState() {
                let name = window.location.hash

                if (name.search('o-pracowni') === 2) { this.bold = 'about' }
                else if (name.search('kontakt') === 2) { this.bold = 'contact' }
                else { this.bold = 'main' }
            }
        },

        mounted() {
            this.menuSectionState()
        }
    }
</script>

<style lang="scss" scoped>
    $white: #fbfbf9;
    $gray: #3d413d;
    $blue: #45a19f;

    .navbar-wrapper{
        margin: 2rem 0;

        display: flex;
    }

    img{
        width: 2.5rem;
        height: auto;

        //padding-right: 0.5rem;
    }

    header{
        width: 100%;

        display: flex;
        align-items: end;
        justify-content: space-between;

        border-bottom: solid 0 $gray;

        span{
            margin-left: 1.75rem;

            font-size: 1.25rem;
        }
    }

    nav{
        text-align: right;

        font-size: 1.25rem;

        a{
            position: relative;

            margin-left: 2rem;
            padding-bottom: 0.25rem;

            color: $gray;

            text-decoration: none;

            &::after{
                content: "";

                width: 100%;
                height: 1px;

                display: block;

                position: absolute;
                bottom: 0;
                left: 0;

                opacity: 0;

                background: $gray;

                transition: opacity 400ms;
            }

            &:hover::after{
                opacity: 1;
            }
        }
    }
</style>