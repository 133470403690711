<template>

    <section class="about-wrapper">
        <h1>o&nbsp;mnie</h1>

        <p class="text-block">
            Autorskie biuro projektowe założone w 2006 roku przez absolwenta Politechniki Krakowskiej, architekta Marka Stojanowskiego. Pracownia ma na swoim koncie kilkadziesiąt realizacji budynków mieszkalnych, usługowych i przemysłowych, głównie na terenie województwa śląskiego.
        </p>

        <p>inwestorzy</p>

        <Splide :options="{ type: 'loop', speed: 500, fixedWidth: 'fit-content', gap: '5rem', pauseOnHover: false, autoplay: true, interval: 2500 }">

            <SplideSlide>
                <img src="./../assets/investors/adam.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/akatex.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/alux.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/atk.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/beskid-dev.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/bielsko-biala.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/cemex.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/chybie.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/czechowice.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/etno-bank.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/gliwice.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/grabo.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/katowice.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/ksiaznica.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/linde.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/lodygowice.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/lotos.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/polski-dom.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/pro.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/slawkow.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/starling.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/strumien.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/times.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/tychy.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/ustron.jpg" alt="photo">
            </SplideSlide>

            <SplideSlide>
                <img src="./../assets/investors/wisla.jpg" alt="photo">
            </SplideSlide>

        </Splide>

    </section>

</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide'
    import '@splidejs/splide/dist/css/splide.min.css';

    export default {
        name: 'About',

        components: { Splide, SplideSlide }
    }
</script>

<style lang="scss" scoped>
    li { width: fit-content; }
</style>