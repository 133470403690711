<template>

    <section class="thumbnails-wrapper">

        <Thumbnail photo="mlodziezowa" header="zabudowa szeregowa" />
        <!--  <Thumbnail photo="kubiszowka" header="brak danych" />  -->
        <Thumbnail photo="times-czechowice" header="salon times" />
        <Thumbnail photo="dom-bb-lang" header="dom jednorodzinny" />
        <Thumbnail photo="olszowka-stomatolog" header="budynek usługowy" />
        <Thumbnail photo="warszawska" header="salon PKOBP" />
        <!--  <Thumbnail photo="niwna" header="zabudowa szeregowa" />  -->
        <Thumbnail photo="atk" header="magazyn firmy" />
        <!--  <Thumbnail photo="dom-bb-polna" header="dom jednorodzinny" />  -->
        <Thumbnail photo="dom-bb-rybna" header="dom jednorodzinny" />
        <Thumbnail photo="domek-kreta" header="domek kreta" />
        <Thumbnail photo="akatex" header="budynek akatex"/>
        <Thumbnail photo="hotel-blonia" header="hotel" />
        <!--  <Thumbnail photo="lotos" header="brak danych" />  -->
        <Thumbnail photo="strazacka" header="magazyn" />
        <Thumbnail photo="viessmann" header="salon viessmann" />

    </section>

</template>

<script>
    import Thumbnail from '@/components/Thumbnail'

    export default {
        name: 'Main',

        components: {Thumbnail}
    }
</script>

<style scoped lang="scss">
    .thumbnails-wrapper{
        width: 100%;

        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 900px){
        .thumbnails-wrapper{
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 576px){
        .thumbnails-wrapper{
            grid-template-columns: repeat(1, 1fr);
        }
    }
</style>