<template>

    <section class="thumbnail-wrapper">

        <a :href="`/../#/article/${photo}`">
            <article class="img-wrapper">
                <img v-if="this.photo !== 'atk'" :src="`./assets/thumbnails/${this.photo}-1.jpg`" :alt="`zdjęcie: ${photo}`">
                <img v-else :src="`./assets/thumbnails/atk.jpg`" :alt="`zdjęcie: ${photo}`">
            </article>
        </a>

    </section>

</template>

<script>
    export default {
        name: 'Thumbnail',

        props: {
            photo: {type: String, required: true},
            header: {type: String},
        }
    }
</script>

<style lang="scss" scoped>
    $white: #fbfbf9;
    $gray: #3d413d;
    $blue: #45a19f;

    a{
        height: fit-content;

        text-decoration: none;
        color: inherit;
    }

    .img-wrapper{
        position: relative;

        overflow: hidden;

        img{
            width: 100%;

            position: relative;

            border-radius: 2px;

            filter: grayscale(1);
            transition: 400ms filter;

            &:hover{
                filter: grayscale(0);
            }
        }
    }
</style>