<template>

    <section class="contact-wrapper">

        <h1>kontakt</h1>

        <article class="grid">

            <section>
                <p class="decoration" style="margin-top: 0">Marek Stojanowski STUDIO S1</p>
                <p>ul. Lubelska 22</p>
                <p>43-300 Bielsko – Biała</p>
            </section>

            <section>
                <p style="margin-top: 0">NIP 937 151 28 80</p>
                <p>REGON 070839739</p>
            </section>

            <section>
                <p>
                    <a href="tel:604667042" class="link">tel. 604 667 042</a>
                </p>

                <p>
                    <a href="mailto:marek.stojanowski@poczta.fm" class="link">marek.stojanowski@poczta.fm</a>
                </p>

                <p>
                    <a href="https://stojanowski.pl" class="link">www.stojanowski.pl</a>
                </p>
            </section>

        </article>

    </section>

</template>

<script>
    export default {
        name: 'Contact'
    }
</script>

<style lang="scss" scoped>
    $white: #fbfbf9;
    $gray: #3d413d;
    $blue: #45a19f;

    .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .link{
        color: $gray;

        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }

    @media (max-width: 980px){
        .grid{
            display: block;

            section{
                margin-bottom: 3rem;
            }
        }
    }
</style>