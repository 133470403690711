<template>

    <section class="main-wrapper">

        <Navbar />

        <router-view />

    </section>

</template>

<script>
    import Navbar from '@/components/Navbar'
    import Contact from '@/views/Contact'

    export default {
        name: 'App',
        components: {Contact, Navbar}
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;600&display=swap');

    $white: #fbfbf9;
    $gray: #3d413d;
    $blue: #45a19f;

    *, *::before, *::after{
        box-sizing: border-box;
    }

    body{
        width: 100vw;

        margin: 1rem 0;
        padding: 0;

        overflow-x: hidden;

        color: $gray;
        background: $white;

        font-family: 'Lato', sans-serif;
        font-weight: 300;

        letter-spacing: 1px;
    }

    h1{
        width: 100%;

        font-weight: 600;
        font-size: 1.35rem;
    }
    p{
        font-size: 1.2rem;
    }

    .decoration{
        font-weight: 400;
    }
    .text-block{
        text-align: justify;
        line-height: 2;
    }

    .splide__pagination{ display: none !important; }
    .splide__arrows{ display: none !important; }

    .main-wrapper{
        width: 80vw;

        margin: 0 auto;
    }
</style>