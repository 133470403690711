import { createRouter, createWebHashHistory } from 'vue-router'

import Main from '@/views/Main'
import About from '@/views/About'
import Contact from '@/views/Contact'
import Article from '@/views/Article'

const routes = [
    {
      path: '/',
      name: 'Main',
      component: Main
    },
    {
        path: '/o-pracowni',
        name: 'About',
        component: About
    },
    {
        path: '/kontakt',
        name: 'Contact',
        component: Contact
    },

    {
        path: '/article/:name',
        name: 'Article',
        component: Article
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    mode: 'hash',
    routes
})

export default router