<template>

    <section class="article-wrapper">

        <img :src="`/../assets/thumbnails/${this.articleData.name}-1.jpg`" alt="photo">

        <article>
            <p style="margin-top: 0"><span class="decoration">typ:</span> {{this.articleData.type}}</p>
            <p><span class="decoration">lokalizacja:</span> {{this.articleData.location}}</p>
            <p><span class="decoration">powierzchnia użytkowa:</span> {{this.articleData.area}} m<sup>2</sup></p>
            <p><span class="decoration">rok realizacji:</span> {{this.articleData.projectYear}} r.</p>
        </article>

        <img :src="`/../assets/thumbnails/${this.articleData.name}-2.jpg`" onerror="this.style.display = 'none'" alt="photo">
        <img :src="`/../assets/thumbnails/${this.articleData.name}-3.jpg`" onerror="this.style.display = 'none'" alt="photo">
        <img :src="`/../assets/thumbnails/${this.articleData.name}-4.jpg`" onerror="this.style.display = 'none'" alt="photo">

    </section>

</template>

<script>
    export default {
        name: 'Article',

        data (){
            return{
                articleName: '',
                articleData: '',

                articlesContent: [
                    {
                        name: 'akatex',
                        type: 'produkcyjno-biurowy',
                        location: 'Bielsko-Biała',
                        area: '855',
                        projectYear: '2007'
                    },
                    {
                        name: 'times-czechowice',
                        type: 'biurowo-produkcyjny',
                        location: 'Czechowice-Dziedzice',
                        area: '3 600',
                        projectYear: '2016-2017'
                    },
                    {
                        name: 'dom-bb-lang',
                        type: 'mieszkalny jednorodzinny',
                        location: 'Bielsko-Biała',
                        area: '280',
                        projectYear: '2016'
                    },
                    {
                        name: 'warszawska',
                        type: 'biurowo–usługowo-handlowy',
                        location: 'Bielsko-Biała',
                        area: '1 840',
                        projectYear: '2015'
                    },
                    {
                        name: 'atk',
                        type: 'produkcyjno-magazynowy',
                        location: 'Kobiernice',
                        area: '2 200',
                        projectYear: '2010'
                    },
                    {
                        name: 'dom-bb-rybna',
                        type: 'mieszkalny jednorodzinny',
                        location: 'Bielsko-Biała',
                        area: '440',
                        projectYear: '2011'
                    },
                    {
                        name: 'domek-kreta',
                        type: 'mieszkalny jednorodzinny',
                        location: 'Czechowice-Dziedzice',
                        area: '90',
                        projectYear: '2003'
                    },
                    {
                        name: 'hotel-blonia',
                        type: 'hotelowy',
                        location: 'Bielsko-Biała',
                        area: '4 390',
                        projectYear: '2002-2011'
                    },
                    {
                        name: 'mlodziezowa',
                        type: 'mieszkalny, zabudowa szeregowa',
                        location: 'Bielsko-Biała',
                        area: '1 490',
                        projectYear: '2017'
                    },
                    {
                        name: 'strazacka',
                        type: 'biurowo-magazynowy',
                        location: 'Bielsko-Biała',
                        area: '1 410',
                        projectYear: '2007'
                    },
                    {
                        name: 'olszowka-stomatolog',
                        type: 'mieszkalno-usługowy z gabinetami lekarskimi',
                        location: 'Bielsko-Biała',
                        area: '540',
                        projectYear: '2010'
                    },
                    {
                        name: 'viessmann',
                        type: 'handlowo-usługowy',
                        location: 'Bielsko-Biała',
                        area: '160',
                        projectYear: '2004'
                    }
                ]
            }
        },

        methods: {

            getArticle() {
                let name = window.location.hash

                name = name.replaceAll('#', '')
                name = name.replaceAll('/', '')
                name = name.replace('article', '')

                this.articleName = name

                this.showData()
            },
            showData() {
                for (let number = 0; number < this.articlesContent.length; number++) {

                    if (this.articlesContent[number].name === this.articleName) {
                        this.articleData = this.articlesContent[number]
                    }
                }
            }
        },

        mounted() {
            this.getArticle()
        }
    }
</script>

<style lang="scss" scoped>
    $white: #fbfbf9;
    $gray: #3d413d;
    $blue: #45a19f;

    .article-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        img{
            width: 100%;

            margin-bottom: 2rem;

            border-radius: 2px;
        }

        article{
            grid-column: 2;
            grid-row: 1 / 4;

            padding-left: 4rem;
        }
    }

    @media (max-width: 736px){
        .article-wrapper{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);

            article{
                padding-left: 0;

                grid-row: 3;
            }

            img{
                margin-bottom: 2rem;
            }
        }
    }
</style>